// React Component
import React from 'react'

// App Component
import { AppLayout, HeaderLayout } from '../../layouts'

// Features
import { ProcessCreate } from "../../features/process"

const CreateProcessView = () => {
    return (
        <AppLayout>
            <>
                <HeaderLayout loadcompany={false} />
                <div className="px-3 mt-2 h-100 ">
                    <div className='container' style={{ marginTop: "10%" }}>
                        <ProcessCreate />
                    </div>

                </div>
            </>
        </AppLayout>
    )
}

export default CreateProcessView