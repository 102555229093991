import React from 'react'
import TextareaAutosize from 'react-textarea-autosize';


import Buttons from './Buttons'


interface textAreaProps {
    label: string
    rows: number
    classNameArea: string
    classNameContainer: string
    placeholder: string
    onChange(event: React.SyntheticEvent): any
    value: string
    interviewId: string
    sendAnswer: (e: React.MouseEvent<HTMLButtonElement>, answerId: string) => void;
    spinner: boolean
    maxRows: number
    minRows: number
    sendButton: boolean
    button: boolean
    readOnly: boolean,
    name: string,
    updateAnswer: (answer: string) => void;
    handleFocus?: () => void;
    handleBlur?: () => void;
    recognition?: boolean

}


const TextAreaForm = (props: textAreaProps) => {
    const {
        button,
        name,
        sendButton,
        label,
        rows,
        classNameArea,
        classNameContainer,
        placeholder,
        onChange,
        value,
        interviewId,
        sendAnswer,
        spinner,
        maxRows,
        minRows,
        readOnly,
        updateAnswer,
        handleFocus,
        handleBlur,
        recognition,
    } = props

    const [isListening, setIsListening] = React.useState(false);
    const recognitionRef = React.useRef<SpeechRecognition | null>(null);


    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            sendAnswer(event as any, interviewId);
            event.preventDefault();
        }
    };

    React.useEffect(() => {
        if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            recognitionRef.current = new SpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event) => {
                const transcript = Array.from(event.results)
                    .map(result => result[0])
                    .map(result => result.transcript)
                    .join('');
                updateAnswer(transcript);
            };

            recognitionRef.current.onerror = (event) => {
                console.error('Speech recognition error:', event.error);
                setIsListening(false);
            };

        } else {
            alert("Your browser does not support speech recognition. Please try a supported browser like Chrome.");
            console.error("Speech recognition is not supported in this browser.");
        }

    }, []);

    const toggleListening = () => {
        if (!recognitionRef.current) {
            return;
        }

        if (isListening) {
            recognitionRef.current.stop();
        } else {
            recognitionRef.current.start();
        }

        setIsListening(!isListening);
    };


    return (
        <div className={`${classNameContainer}`}>

            <TextareaAutosize
                minRows={minRows}
                maxRows={maxRows}
                className={`border-light ${classNameArea}`}
                placeholder={placeholder}
                onChange={(event) => onChange(event)}
                onKeyPress={handleKeyPress}
                value={value}
                name={name}
                id="textarea"
                rows={rows}
                style={{ resize: 'none', overflow: 'auto' }}
                readOnly={readOnly}
                maxLength={3200}
                onFocus={handleFocus}
                onBlur={handleBlur}

            />

            {button &&
                <div className="d-flex">
                    {recognition &&
                        <Buttons
                            buttonType="submit"
                            name=""
                            onClick={toggleListening}
                            isLoading={false}
                            btnClass={`btn-outline-dark py-1 rounded-pill ms-1  mb-1 p-1 px-2`}
                            labelClass="text-white"
                            styleProps={{ height: 30, marginBottom: '0' }}
                            withIcon={true}
                            iconName={`fa-solid ${isListening ? 'bi bi-mic-mute text-theme' : 'bi bi-mic text-white'} fs-6 `}
                            btnStyle={{ height: 58 }}
                            arrowVisible={false}
                            arrowColor=''
                        />
                    }

                    <Buttons
                        buttonType="submit"
                        name=""
                        onClick={(e) => { sendAnswer(e, interviewId) }}
                        isLoading={false}
                        btnClass={`btn-outline-dark rounded-pill ms-1 mb-1 me-2 p-1 px-2 ${(spinner || !sendButton) && 'disabled'} `}
                        labelClass="text-white fw-bold"
                        styleProps={{ height: 30, marginBottom: '0' }}
                        withIcon={true}
                        iconName={`fa-solid ${spinner ? 'fa-spinner' : "bi bi-send"} fs-6 text-theme`}
                        btnStyle={{ height: 58 }}
                        arrowVisible={false}
                        arrowColor=''
                    />
                </div>
            }

        </div>
    )
}
export default TextAreaForm