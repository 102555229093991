import React, { Dispatch, SetStateAction, useRef } from "react";
import { useTranslation } from "react-i18next";

// Components and services
import { useAppSelector } from "../../stores";
import { FamlaServices } from "../../services";
import { InputForm, Spiner } from "../../components";

// Interfaces
import { iAlert } from "../../interfaces";
import { iUser } from "../../components/type";

interface ChildProps {
    setUsers: Dispatch<SetStateAction<iUser[]>>;
    companyId: string
}

const AddAdmin = (props: ChildProps) => {
    const { setUsers, companyId } = props;
    const closeRef = useRef(null)
    const { t } = useTranslation();
    const users = useAppSelector((state) => state.auth);

    const [state, setState] = React.useState({
        email: "",
        isSubmit: false,
    });

    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: "",
        message: "",
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (state.email.length === 0) {
            setAlert({
                isActive: true,
                type: "danger",
                message: t("dashboard.enterValideEmail"),
            });
            return;
        }

        setState((prevState) => ({ ...prevState, isSubmit: true }));

        const newFormData = {
            mail: [state.email],
        };

        try {
            const data = await FamlaServices("api_host", `/company/add_admin/`, "PUT", newFormData, users.access_token);

            if (data.status === 200) {
                setAlert({ isActive: true, type: "success", message: t("dashboard.adminAddedSuccessfully"), });
                setState({ email: "", isSubmit: false });
                let data = await FamlaServices("api_host", `/users/company/${companyId}?page=${1}&limit=${10000000}`, "GET", "", users.access_token);


                if (data.status === 200) {
                    const paginage = data.body.paginage;
                    const adminsWithRole = data.body.data.admin.map((user: iUser) => ({ ...user, role: "admin" }));
                    setUsers(adminsWithRole);
                }
            } else if (data.status === 400) {
                setAlert({ isActive: true, type: "danger", message: t("navigation.youAreNotAuthorizedToPerformThisAction"), });
            } else if (data.status === 404) {
                setAlert({ isActive: true, type: "danger", message: t("dashboard.companyNotFound"), });
            } else if (data.status === 401) {
                setAlert({ isActive: true, type: "danger", message: t("navigation.youAreNotLoggedIn") });
            } else {
                setAlert({ isActive: true, type: "danger", message: t("navigation.anErrorOcured") });
            }
        } catch (error) {
            setAlert({ isActive: true, type: "danger", message: t("navigation.anErrorOcured") });
        } finally {
            setState((prevState) => ({ ...prevState, isSubmit: false }));
        }
    };

    return (
        <div className="modal" data-bs-backdrop="static" id="addAdmin" tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark">
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-white">{t('dashboard.addNewAdminTitle')}</h5>
                        <button
                            type="button"
                            className="btn-close bg-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        {alert.isActive && (
                            <div className={`alert alert-${alert.type}`} role="alert">
                                {alert.message}
                            </div>
                        )}
                        <InputForm
                            inputType="email"
                            inputLabel={t("words.email")}
                            inputName="email"
                            inputValue={state.email}
                            isFloatingLabel={true}
                            labelClass="text-theme"
                            onChange={handleChange}
                            inputContainerClass={"mb-3 p-0"}
                            inputClass="bg-dark text-light text-bg-dark"
                            inputStyle={{ height: 0, padding: 0 }}
                        />
                    </div>
                    <div className="modal-footer">
                        <button
                            ref={closeRef}
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            {t("words.close")}
                        </button>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="btn btn-primary"
                        >
                            {state.isSubmit ? (
                                <Spiner className="spinner-border-sm" />
                            ) : (
                                <>{t('words.add')}</>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAdmin;
