export default function downloadFileDirect() {
    const url = "https://fl-services.famla.ai:9540/public/file/guide.pdf";
    const filename = 'Guide.pdf';
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}