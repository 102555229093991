import React from "react";
import { useTranslation } from "react-i18next";
import { Buttons, InputForm, TextAreaForm } from "../../components";
import { FamlaServices } from "../../services";
import { useAppSelector } from "../../stores";
import { Colors, Images } from "../../constants";

// interface
import { iAlert } from "../../interfaces";
import { Routes } from "../../routes";

interface StateType {
  message: string;
  isLoading: boolean;
  name: string;
  email: string;
  subject: string;
  isSubmit: boolean;
  sendCopy: boolean;
}

const ContactUs = () => {
  const users = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const [state, setState] = React.useState<StateType>({
    isLoading: true,
    message: "",
    name: users.user.fullname,
    email: users.user.email,
    isSubmit: false,
    subject: "",
    sendCopy: false,
  });

  const [alert, setAlert] = React.useState<iAlert>({
    isActive: false,
    type: "",
    message: "",
  });

  const handleChange = (event: any) => {
    const { type, name, value, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setState({ ...state, [name]: fieldValue });
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (!state.email) {
      setAlert({
        isActive: true,
        type: "danger",
        message: t("views.legalDocs.contact.invalidEmail"),
      });
      return;
    }

    setState({ ...state, isSubmit: true });
    const formData = {
      name: state.name,
      email: state.email,
      subject: state.subject,
      message: state.message,
    };

    const data = await FamlaServices(
      "api_host",
      `/contact_us/`,
      "POST",
      formData,
      users.access_token
    );

    if (data.status === 200) {
      setAlert({
        isActive: true,
        type: "success",
        message: t("views.legalDocs.contact.success"),
      });
      setState({
        ...state,
        email: "",
        subject: "",
        message: "",
        sendCopy: false,
        isSubmit: false,
      });
    } else {
      setAlert({
        isActive: true,
        type: "danger",
        message: t("views.legalDocs.contact.error"),
      });
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${Images.bg_2})`,
        backgroundSize: "cover",
        height: "100vh",
      }}
    >

      <div
        style={{
          backgroundColor: Colors.bg_dark_blur_2,
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="container text-white">
          <img className="img-fluid position-absolute top-0 start-0 mt-2" src={Images.famla} alt="famla" style={{ height: 30 }} />
          <div className="row justify-content-center">
            {/* Left Section */}
            <div className="col-md-6 mb-4">
              <div>
                <a
                  className="text-decoration-none text-white fs-2"
                  href={Routes.DASHBOARD}
                >
                  <i className="fa-solid fa-arrow-left me-2"></i>
                  {t("navigation.contactUs")}
                </a>
                <h3 className="text-theme fw-bold mt-4">
                  {t("views.legalDocs.contact.gettingInTouch")}
                </h3>
                <p className="my-4">
                  {t("views.legalDocs.contact.eitherFill")}
                </p>
                <div className="text-light">contact@famla.ai</div>
              </div>
            </div>
            {/* Right Section */}
            <div className="col-md-6">
              <div className="shadow p-4 bg-dark rounded">
                <InputForm
                  inputType="subject"
                  inputLabel={t('words.subject')}
                  inputName="subject"
                  inputValue={state.subject}
                  isFloatingLabel={true}
                  labelClass="text-theme"
                  onChange={(e) => handleChange(e)}
                  inputContainerClass={"mb-3 p-0"}
                  inputClass="bg-dark text-light bg-black"
                  inputStyle={{ height: 0, padding: 0 }}
                />
                <TextAreaForm
                  name="area"
                  sendButton={false}
                  button={false}
                  label=""
                  rows={1}
                  classNameArea="w-100 bg-dark text-light bg-black px-3 rounded-3 "
                  classNameContainer="w-100"
                  placeholder={t('words.message') + '...'}
                  onChange={(e) => { handleChange(e); }}
                  value={state.message}
                  interviewId=""
                  sendAnswer={(e) => { }}
                  spinner={false}
                  maxRows={5}
                  minRows={6}
                  readOnly={false}
                  updateAnswer={() => { }}
                />
                {alert.isActive && (
                  <div
                    className={`alert alert-${alert.type} mt-3`}
                    role="alert"
                  >
                    {alert.message}
                  </div>
                )}
                <div className="form-check my-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="sendCopy"
                    checked={state.sendCopy}
                    onChange={handleChange}
                  />
                  <label className="form-check-label">
                    {t("views.legalDocs.contact.sendMeCopy")}
                  </label>
                </div>
                <div className="d-flex justify-content-end">
                  <Buttons
                    buttonType="submit"
                    name={t('words.send')}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    isLoading={state.isSubmit}
                    btnClass="btn-theme ms-2 bg-theme border*theme w-25 mail-to"
                    labelClass="text-white fw-bold"
                    styleProps={{ height: 40 }}
                    withIcon={false}
                    iconName=""
                    btnStyle={{}}
                    arrowVisible={false}
                    arrowColor="text-theme pt-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
