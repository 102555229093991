// React Component
import React from "react"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"

// App Component
import { DiagrammList, Dropdown, InterviewList, RecentProcessesList } from '../components'
import { Routes } from "../routes"
import { useAppSelector } from "../stores"

//utils
import { slice, downloadFileDirect } from "../utils"
import { FamlaServices } from "../services"

// interface
import { iInterview, iUser } from "../components/type"


interface NavBarProps {
    recentProcess: any
    processes: Array<iInterview>
    processName: string
    updateInterviewwId: (newId: string | undefined) => void;
    updateCollaborators: (col: any) => void;
    interviwsId: string | undefined
    isLoadingProcess: boolean,
    closeOffCanvas: any
    isloadingCollaborators: boolean
    deleteInterviews: (id: string, actionName: string) => void
    deleteSwimlaneDiagram: (id: string, actionName: string) => void
    setModalWithSwimlaneId: (id: string, actionName: string) => void
    renameSwimlane: (id: string, actionName: string) => void
    shareSwimlane: (id: string, actionName: string) => void
    swimlanes: Array<any>
    processId?: string
    loadDiagram: boolean
    prepareAndShowModal: (user: iUser | undefined) => void;

}



const NavLayout = (props: NavBarProps) => {
    let {
        setModalWithSwimlaneId,
        renameSwimlane,
        updateCollaborators,
        shareSwimlane,
        processId,
        loadDiagram,
        swimlanes,
        deleteInterviews,
        deleteSwimlaneDiagram,
        processes,
        recentProcess,
        processName,
        updateInterviewwId,
        interviwsId,
        isLoadingProcess,
        closeOffCanvas,
        isloadingCollaborators,
        prepareAndShowModal
    } = props
    const [selected, setSelected] = React.useState<any>(interviwsId)
    const { t } = useTranslation();
    const handleClick = (newId: string | undefined, col?: iUser) => {
        updateInterviewwId(newId);
        updateCollaborators(col)

        if (closeOffCanvas) {
            closeOffCanvas.current.click();
        }
    };
    const role = useAppSelector(state => state.role.role)
    const users = useAppSelector(state => state.auth);

    const updateselection = (roleId: string) => {
        setSelected(roleId === selected ? null : roleId)
    };

    const handleClickNonMappedElement = () => {
        setSelected(null);
    };


    const naviageToNewProcess = async (processId: string) => {
        let data = await FamlaServices("api_host", `/interview/process/${processId}`, "GET", "", users.access_token);
        if (data.status === 200) {
            updateselection(data.body.data._id)
            window.location.replace(Routes.PROCESS_INTERVIEW + data.body.data._id);
        }
    }

    return (
        <main className="container-fluid m-2 ps-2 position-relative " style={{ height: "100%", width: "100%", }} >

            <div className="w-100 overflow-auto h-100" id="navcontainer">
                <div style={{ marginBottom: "100px" }}>
                    <NavLink to={Routes.PROCESS_CREATE} className="text-decoration-none text-light fs-6 px-0 w-auto "  >

                        <div className="btn btn-secondary rounded-4 py-1 bg-dark border-0 p-2 w-auto text-gradient"  >
                            <span><i className="fa-solid fa-plus pe-0"></i></span>
                            <span className="ms-1">{t('views.processInterview.newProcess')}</span>
                        </div>

                    </NavLink>

                    <RecentProcessesList
                        recentProcess={recentProcess}
                        isloadingCollaborators={isloadingCollaborators}
                        navigateToNewProcess={naviageToNewProcess}
                    />

                    <InterviewList
                        role={role}
                        selected={selected}
                        processName={processName}
                        interviwsId={interviwsId}
                        isLoadingProcess={isLoadingProcess}
                        processes={processes}
                        users={users.user}
                        handleClick={handleClick}
                        handleClickNonMappedElement={handleClickNonMappedElement}
                        updateselection={updateselection}
                        deleteInterviews={deleteInterviews}
                        prepareAndShowModal={prepareAndShowModal}
                    />

                    <DiagrammList
                        loadDiagram={loadDiagram}
                        swimlanes={swimlanes}
                        role={role}
                        processId={processId}
                        deleteSwimlaneDiagram={deleteSwimlaneDiagram}
                        renameSwimlane={renameSwimlane}
                        shareSwimlane={shareSwimlane}
                        setModalWithSwimlaneId={setModalWithSwimlaneId}
                        slice={slice}
                        t={t}
                    />
                </div>

            </div>


            <div className="position-absolute  w-100 " style={{ bottom: "0px", backgroundColor: "#1F1F21", marginLeft: "-16px" }}>
                <div className="mb-3 bg-transparent  ps-3">
                    <Dropdown
                        dropName={t('words.help')}
                        dropdownType="dropdown"
                        iconClassName='fa-regular fa-circle-question me-2'
                        buttonDropClassName='btn btn-color text-white  border-0 '
                        styleButton={{}}
                        deleteProcess={() => { }}
                        action4={t('navigation.contactUs')}
                        action5={t('navigation.privacyHub')}
                        theRouteOfAction4={Routes.CONTACTUS}
                        theRouteOfAction5={Routes.PRIVACY}
                        faq={t('words.faq')}
                        routeFaq={Routes.FAQ}
                        editdiadram={t('navigation.userGuide')}
                        runEditor={downloadFileDirect}
                    />

                    <Dropdown
                        dropName={users.user.fullname}
                        dropdownType="dropdown"
                        iconClassName='fa-regular  fa-user me-2'
                        buttonDropClassName='btn btn-secondary bg-teal rounded-4 mt-2 border-0'
                        styleButton={{}}
                        deleteProcess={() => { }}
                        action2={t('navigation.logOut')}
                        theRouteOfAction4=""
                        theRouteOfAction5=""
                        dataBsTarget="#inviteUser"
                        routeFaq=""
                        action6={t('words.languages')}
                        dataBsTargetAction6="#changeLanguageModal"
                    />
                </div>

            </div>

        </main>
    )
}

export default NavLayout