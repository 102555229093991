import React, { useState, useCallback } from 'react';
import { RenderFormatteContent, slice } from '../../utils';
import { iInterviewContent } from '../../components/type';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../stores';

interface iprops {
    fullname: string;
    questionData: iInterviewContent;
    file: File | null;
    fileIn: boolean | undefined;
    index: number;
    onFileSelect: (file: File) => void;
    updateContent: (answer_id: string, content: string,) => void;
    lastContent: number
}

const ContentQuestion = (props: iprops) => {
    const { fullname, questionData, file, fileIn, index, updateContent, onFileSelect, lastContent } = props;

    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [modifiedResponse, setModifiedResponse] = useState(questionData.content || '');
    const [fileName, setFileName] = useState('');
    const role = useAppSelector((state) => state.role.role);
    const handleSave = (answer_id: string, content: string) => {
        if (modifiedResponse.trim().length > 0) {
            updateContent(answer_id, content);
            setIsEditing(false);
            setModifiedResponse('')
        }
    };

    const handleDrop = useCallback(
        (e: React.DragEvent) => {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            if (isValidFile(file)) {
                onFileSelect(file);
                setFileName(file.name);
            }
        },
        [onFileSelect]
    );

    const handleFileInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            if (file && isValidFile(file)) {
                onFileSelect(file);
                setFileName(file.name);
            }
        },
        [onFileSelect]
    );

    const isValidFile = (file: File): boolean => {
        const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
        const maxSize = 5 * 1024 * 1024;
        return validTypes.includes(file.type) && file.size <= maxSize;
    };

    return (
        <div className="mt-4 d-flex align-items-center content-container">
            {(!isEditing && lastContent - 1 === index) ? (
                <button
                    onClick={() => setIsEditing(true)}
                    className="btn btn-outline-secondary d-flex justify-content-center align-items-center text-light border-0 rounded-circle edit-btn me-3"
                    style={{ width: '40px', height: '40px' }}
                >
                    <i className="bi bi-pencil"></i>
                </button>
            ) :
                (
                    <div
                        className="d-flex justify-content-center align-items-center text-light border-0 rounded-circle edit-btn me-3"
                        style={{ width: '40px', height: '40px' }}
                    >
                    </div>
                )}

            <div className="w-100 bg-dark p-3 rounded-4 card me-3">
                <div className="d-flex align-items-center">
                    <i className="bi bi-person fs-5 text-light"></i>
                    <span className="ps-1 text-light">{fullname}</span>
                </div>

                <div className="mt-2">
                    {isEditing ? (
                        <div>
                            <textarea
                                value={modifiedResponse}
                                onChange={(e) => setModifiedResponse(e.target.value)}
                                className="form-control bg-dark text-light border-light border-1"
                                rows={4}
                            />
                            <div className="mt-2 d-flex justify-content-between align-items-center">
                                <div className="text-light">
                                    {fileName && (
                                        <span className='bi bi-file-earmark-arrow-up fs-5 teal' > <span className='fs-6'>{slice(fileName, 5) + '.PDF'}</span></span>
                                    )}
                                </div>

                                <div className="d-flex">
                                    {(fileIn|| file) && index === 1 &&
                                        <div>
                                            <label
                                                htmlFor={`file-upload-${index}`}
                                                className="btn btn-outline-secondary d-flex justify-content-center align-items-center text-light border-0 rounded-circle me-1"
                                                style={{ width: '40px', height: '40px', cursor: 'pointer' }}
                                            >
                                                <i className="bi bi-upload"></i>
                                            </label>
                                            <input
                                                id={`file-upload-${index}`}
                                                type="file"
                                                onChange={handleFileInput}
                                                style={{ display: 'none' }}
                                            />
                                        </div>}

                                    <button
                                        onClick={() => setIsEditing(false)}
                                        className="btn btn-danger btn-sm rounded-pill bg-theme border-0"
                                    >
                                        {t('words.cancel')}
                                    </button>

                                    <button
                                        onClick={() => handleSave(questionData._id, modifiedResponse)}
                                        className="btn btn-success btn-sm ms-1 rounded-pill border-0 bg-teal "
                                    >
                                        {t('words.send')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p className="fs-6 text-light fw-lighter custom-control m-0">
                            {questionData.content && RenderFormatteContent(questionData.content)}
                            {(((questionData.step[0] === 1 && questionData.step[1] === 2) || file) && index === 1) && role !== "WORKER" && (
                                <i className="bi bi-file-earmark-arrow-up fs-1 text-secondary"></i>
                            )}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContentQuestion;
