
//React
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

//store
import { slice } from '../../utils';

//interface

interface FileUploadProps {
    onFileSelect: (file: File) => void;
}

export default function FileUpload({ onFileSelect }: FileUploadProps) {
    const { t } = useTranslation()
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);
    const [fileName, setFileName] = React.useState('')
    const [alert, setAlert] = React.useState({
        type: "",
        msg: "",
        isActive: false
    })

    const handleDrop = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (isValidFile(file)) {
            onFileSelect(file);
        }
    }, [onFileSelect]);

    const handleFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file && isValidFile(file)) {
            onFileSelect(file);

            setFileName(file?.name)
        } else {
            setAlert({ isActive: true, msg: t('views.processInterview.validFille'), type: "warning" })
        }
        if (alert.isActive) {
            setTimeout(() => {
                setAlert({ isActive: false, msg: "", type: "" })
            }, 6000);
        }

    }, [onFileSelect]);

    const isValidFile = (file: File): boolean => {
        const validTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/gif', 'image/webp'];
        const maxSize = 5 * 1024 * 1024;
        return validTypes.includes(file.type) && file.size <= maxSize;
    };
    const handleParentClick = () => {
        fileInputRef.current?.click();
    };


    return (
        <div className='d-flex align-items-center justify-content-center badge ' >
            <div
                onClick={handleParentClick}
                className="border border-1 border-dashed border-secondary rounded p-4 mt-3 text-center  mx-5 w-75"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
            >
                {fileName && (
                    <div className="my-3 teal overflow-hidden text-wrap">
                        <i className="bi bi-file-earmark-check  text-wrap"></i> {slice(fileName, 70)}
                    </div>
                )}
                {alert.isActive && (
                    <div className="my-3 text-warning overflow-hidden text-wrap">
                        <span>{alert.msg}</span>
                    </div>

                )}
                <i className="bi bi-upload fs-1 text-secondary"></i>
                <p className="mb-1 text-light opacity-75 my-2">
                    {t('views.processInterview.dropYourFileHere')}
                    <label className="text-primary text-decoration-none cursor-pointer ms-1">
                        {t('views.processInterview.browse')}
                        <input
                            type="file"
                            className="d-none"
                            accept=".pdf,.jpg,.jpeg,.png,.gif,.webp"
                            onChange={handleFileInput}
                            ref={fileInputRef}
                        />
                    </label>
                </p>
                <small className="text-secondary">{t('views.processInterview.validSize')}</small>
            </div>
        </div>
    );
}