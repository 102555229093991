import React from "react"

interface props {
    className: string
    classParent?: string
}

const Spiner = (props: props) => {
    const { className, classParent } = props
    return (
        <div className={classParent}>
            <div className={`spinner-border text-theme ${className}`} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}
export default Spiner