//React
import React from 'react'

//
import { Routes } from '../../routes'

//
interface ChildProps {
    newElement: string
    create: string
    noResult: boolean
}


const NewProcessOrGroup = (props: ChildProps) => {
    const { newElement, create, noResult } = props
    return (
        <div className='mt-3'>
            {!noResult ?
                <a href={create === "process" ? Routes.PROCESS_CREATE : Routes.CREATE_GROUP_PROCESS} className="cardProcess text-white text-decoration-none">
                    <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                        <div className='d-flex flex-column'>
                            <span className='text-center'><i className="bi bi-plus-circle fs-5 fw-bold "></i></span>
                            <span className=''>{newElement}</span>
                        </div>
                    </div>

                </a>
                :
                <div className=" cardProcess text-white text-decoration-none">
                    <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                        <div className='d-flex flex-column '>
                            <span className='text-center'><i className="fa-solid fa-ban fs-5"></i></span>
                            <span className=''>{newElement}</span>
                        </div>

                    </div>

                </div>
            }
        </div>
    )
}
export default NewProcessOrGroup