const fr = {
    dashboard: {
        addNewAdmin: "Ajouter un admin",
        addNewAdminTitle: "Ajouter un administrateur",
        adminAddedSuccessfully: "Administrateur ajouté avec succès.",
        admin: "Administrateurs",
        answerDelete: "Êtes-vous sûr de vouloir supprimer définitivement cet utilisateur du système ? Cette action ne peut être annulée.",
        dashboard: "Tableau de bord",
        daysRemaining: "Nombre de jours restants",
        deleteUser: "Supprimer l'utilisateur",
        demoteFromAdmin: "Rétrograder d'administrateur",
        confirmUserDemotion: "Voulez-vous vraiment rétrograder cet administrateur en utilisateur simple ?",
        confirmAdminPromotion: "Vous êtes sur le point de promouvoir cet utilisateur au rôle d'administrateur",
        collaborators: "Collaborateurs",
        companyNotFound: "Entreprise non trouvée.",
        lastConnectionDate: "Date de la dernière connexion",
        list: 'liste',
        monthlyTokenConsumption: "Consommation mensuelle de token",
        monthlyTokenConsumed: "Token mensuel consommé",
        promoteToAdmin: "Promouvoir au rang d'administrateur",
        processOwners: " Resp. Processus",
        remainingTokens: "Token restants",
        registrationDate: "Date d'inscription",
        successDemotion: "L'administrateur a été rétrogradé avec succès en utilisateur.",
        successPromotion: "L'utilisateur a été promu avec succès au rôle d'administrateur.",
        users: "Utilisateurs ",
        userAlreadyAdmin: "L'utilisateur est déjà un administrateur.",
        rootUserCannotBeRemoved: "L'administrateur principal ne peut pas être rétrogradé en utilisateur simple.",
        totalUsers: "Utilisateurs",
        totalTokens: "Token",
        tokensConsumed: "Token consommés",
    },
    form: {
        nameRequired: "Le nom est obligatoire",
        createCompany: {
            companyName: "Nom de l'entreprise",
            createACompany: "Créer une entreprise",
            phoneNumber: "N° de téléphone",
            postalCode: "Code postal",
            website: "Site web"
        },
        createGroup: {
            createGroup: "Créer un dossier",
            folderNameExist: "Le nom du dossier existe déjà",
            groupCreateSuccessfully: "Création du dossier réussie",
            selectOwner: "Sélectionner un propriétaire",
            youNotAuthorized: "Vous n'êtes pas autorisé à créer un dossier"
        },
        createProcess: {
            descriptionTooShort: "La description doit comporter au moins 5 caractères.",
            industryRequired: "L'industrie est nécessaire",
            invalidSelection: "Sélection de l'industrie invalide",
            nameIsNotValid: "Le nom ou la description n'est pas valide. 'Test' ou 'test' ne sont pas acceptés.",
            processCannotCreate: "le processus ne peut pas être créé par son utilisateur",
            processCreateLimite: "Limite de création atteinte – passez à Premium pour continuer.",
            processNameAlreadyExist: "Le nom du processus existe déjà",
            processOverview: "Vue du processus",
            selectIndustry: "Sélectionner un secteur d'activité",
            selectLanguage: "Sélectionner une langue d'interview"
        }
    }
    ,

    features: {
        swimlane: {
            approveQuestion: "Êtes-vous sûr de vouloir approuver ce diagramme ?",
            disApproveQuestion: "Êtes-vous sûr de vouloir désapprouver ce diagramme ?",
            exportPDF: "Exporter en PDF",
            exportPNG: "Exporter en PNG",
            exportCSV: "Exporter en CSV",
            exportXES: "Exporter en XES",
            improvSentence: " Nous nous associons à des leaders de l'industrie pour accompagner votre parcours d'amélioration unique. - Disponible bientôt.",
            oops: "Oups ! Quelque chose n'a pas fonctionné",
            pleaseWait: "Le diagramme est en cours de chargement. Il devrait être prêt dans quelques secondes",
            // eslint-disable-next-line no-template-curly-in-string
            s1: "Responsable de processus, vous avez économisé {{time}} précieuses minutes d'entretiens grâce à Famla !",
            // eslint-disable-next-line no-template-curly-in-string
            s2: "{{time}} minutes d'entretien économisées ! Famla accompagne les responsables de processus comme vous.",
            // eslint-disable-next-line no-template-curly-in-string
            s3: "{{time}} minutes d'entretien en moins. Responsable de processus, Famla simplifie votre travail.",
            sucessApprov: "Succès ! Le diagramme a été approuvé avec succès.",
            sucessDisapprov: "Succès ! Le diagramme a été rejeté avec succès.",
            swimlaneNameIsNandatory: "le nom du diagramme est obligatoire",
            versionHistory: "Historique des versions",
            WeSorry: "Nous n'avons pas pu afficher votre diagramme swimlane en raison d'un problème technique, tel qu'une erreur de connectivité réseau, un incident serveur ou un formatage incorrect des données. Pour revenir au chatbot et permettre la régénération automatique du diagramme, veuillez cliquer sur 'Rafraîchir' et patienter pendant le processus de reconstitution.",
            yourChangesWereNotSaved: "Votre modification n'a pas été enregistrée. Nous vous invitons à actualiser la page et à réessayer."

        }
    },

    navigation: {
        allRightsReserved: "Tous droits réservés.",
        allFieldsRequired: "Tous les champs avec (*) sont obligatoires.",
        anErrorOcured: "Une erreur s'est produite, veuillez réessayer plus tard",
        anErrorOcuredProcessNotFund: "Le processus n'a pas été trouvé.",
        anErrorOccurredMappNotFound: "La map n'a pas été trouvée",
        assigningProcessFolder: "Assigner un processus à un dossier",
        approvalCannotCreateByUser: "Vous ne pouvez pas inviter à approuver en tant que collaborateur.",
        backHome: "Revenir à l'accueil",
        backToFamla: "Retour sur famla",
        contactUs: "Contactez-nous",
        deleteConfirmation: "Confirmation de suppression",
        diagramName: "Nom du diagramme",
        diagramsPendingApproval: "Diagrammes en attente d'approbation",
        enterFolderName: "Entrez un nom de dossier",
        enterProcessName: "Entrez un nom de processus",
        enterValideEmail: "Entrez un e-mail valide",
        folderSuccessfully: "Dossier mis à jour avec succès.",
        folderNotFound: "Erreur : Le dossier n'a pas été trouvé",
        interviewNotOver: "L'interview n'est pas terminée",
        inviteOwner: "Inviter un propriétaire de processus",
        inviteAApprover: "Inviter un approbateur",
        inviteCollaborator: "Inviter un collaborateur",
        invitationSentSuccessfully: "Invitation envoyée avec succès",
        logOut: 'Se déconnecter',
        markAsFinal: "Marquage final",
        mapWithNotFound: "Une erreur s'est produite : La map n'a pas été trouvée.",
        noData: "Aucune donnée",
        noRequest: "Aucune demande d'approbation",
        privacyHub: 'Politique de confidentialité',
        shareLink: "Partager le lien",
        selectFolder: "Sélectionner un dossier",
        successfullyUpdated: "Mise à jour effectuée avec succès",
        termsOfUse: "Conditions d'utilisation",
        theProcessHasBeenHalted: "Le processus a été arrêté avec succès.",
        time_remaining: "Temps restant : {{count}} jour{{count, plural, one {} other {s}}}",
        userGuide: "Guide d'utilisation",
        oops: " Oups ! La page n'a pas été trouvée",
        privacyPolicy: "Politique de confidentialité",
        processchange: "Le processus ne peut être modifié qu'une fois qu'il est terminé.",
        processClassifiedWithSuccess: "Processus classé avec succès",
        processCannot: "Vous ne pouvez pas mettre à jour ce processus.",
        processNotFound: "Le processus n'a pas pu être trouvé.",
        proJectNotFound: "Le project n'a pas pu être trouvé.",
        publishQuestion: "Marquer comme final ? Cela rendra le processus en lecture seule et empêchera toute modification ultérieure.",
        roleAlreadyExist: "Le rôle existe déjà",
        roleNotFound: "Une erreur s'est produite. Le rôle n'a pas été trouvé",
        simplyLeverage: "Vous pouvez également vous appuyer sur l'expertise de notre équipe.",
        termsAndConditions: "Conditions d'utilisation",
        youAreNotLoggedIn: "Vous n'êtes pas connecté ",
        youAreNotAuthorized: "Vous n'êtes pas autorisé à mettre à jour ce dossier.",
        youAreNotAuthorizedToPerformThisAction: "Vous n'êtes pas autorisé à effectuer cette action."
    },

    layout: {
        header: {
            approvalRequests: "Demandes d'approbation",
            upgradeToPremium: "Passer premium - {{daysRemaing}} jour restant",
            upgradeToPremiums: "Passer premium - {{daysRemaing}} jours restant",
            upgradeToPrem: "Passer premium",
            updateCompany: "Voir l'entreprise",
            company: 'Entreprises'
        }
    },

    views: {
        auth: {
            accountAlreadyExist: "Compte existant",
            alreadyHaveAccount: "Vous avez déjà un compte ?",
            anErrorOcured: "Une erreur s'est produite, veuillez réessayer plus tard",
            amazingFeatures: "Vous n'êtes qu'à un clic pour découvrir des fonctionnalités étonnantes !",
            compagnyNameIsRequired: "Le nom de l'entreprise est obligatoire",
            continueWithApple: "Continuer avec Apple",
            continueWithGoogle: "Continuer avec Google",
            continueWithMicrosoft: "Continuer avec Microsoft",
            clicking: "Veuillez vérifier votre adresse électronique en cliquant sur le lien figurant dans votre boîte de réception.",
            dontHaveAccount: "Vous n'avez pas de compte ?",
            enterValidMail: "Entrez un e-mail professionnel valide",
            eliminate: "Éliminer les gaspillages, réduire les coûts et maximiser les profits grâce aux méthodologies Lean Six Sigma éprouvées.",
            emailAddres: "Adresse e-mail",
            emailAddresPro: "Adresse e-mail professionnelle",
            emailIsRequired: "L'e-mail est obligatoire",
            emailAddressIs: "L'adresse e-mail est incorrecte ou n'existe pas",
            establish: "Établir des rôles et des responsabilités clairs, éliminer l'ambiguïté, optimiser la collaboration et favoriser la réussite de l'équipe.",
            forgotPassword: "Mot de passe oublié ?",
            famlaTeam: "Equipe famla ",
            getStarted: "Commencer",
            hi: "Salut",
            incorrectEmailOrPassword: "E-mail ou mot de passe invalide",
            logIn: "Se connecter",
            msgReset: "Nous vous enverrons des instructions pour réinitialiser votre mot de passe après avoir saisi votre adresse électronique.",
            newPassword: "Nouveau mot de passe",
            newPassWordIsRequired: "Le nouveau mot de passe est obligatoire",
            password: "Mot de passe",
            passwordsNotMatch: "Les mots de passe ne correspondent pas",
            passwordIsRequired: "Le mot de passe est obligatoire",
            passwordSuccessfully: "Mot de passe réinitialisé avec succès",
            professionalEmail: "Veuillez utiliser une adresse e-mail professionnelle.",
            resetYourPassword: 'Réinitialiser votre mot de passe',
            reset: 'Réinitialiser',
            signUp: "S'inscrire",
            seamlessly: "Documentez vos processus en toute transparence et atteignez la conformité ISO 9001:2015 en toute confiance.",
            thisEmailHasBanned: "Cette adresse e-mail a été retirée de Famla",
            userDoesNotExist: "L'utilisateur n'existe pas",
            verifyYourEmail: "Veuillez vérifier votre adresse e-mail",
            yourSessionHasExp: "Votre session a expiré ou vous êtes connecté sur un autre appareil."

        },
        processView: {
            areYouSure: " Êtes-vous sûr de vouloir supprimer cet élément ?",
            folder: "Dossier",
            folderNotFound: "Dossier non trouvé",
            myProcess: 'Mes processus',
            newProcess: 'Nouveau processus',
            newfolder: "Nouveau Dossier",
            needInspiration: "Besoin d'inspiration ? Explorez ces cas d'usage populaires",
            library: "Librairie",
            popularUseCase: "Cas d'usage populaires",
            processNotFound: "Processus non trouvé",
            viewAll: 'Voir tout'
        },
        processInterview: {
            addCollaborators: "Ajouter un collaborateur",
            browse: 'Explorez',
            collaborators: "Collaborateurs",
            contributor: "Contributeur",
            confirmEditPermissionGrant: "Êtes-vous sûr de vouloir permettre à ce collaborateur d’éditer la cartographie de processus ?",
            confirmEditPermissionRevoke: "Êtes-vous sûr de vouloir révoquer les droits d'édition de cet utilisateur ?",
            comment: "Commentaire",
            dropYourFileHere: "Glissez votre fichier ici, ou",
            diagramsList: "Liste des diagrammes",
            disableEditMode: "Désactiver l'édition",
            deleteDiagramme: "Êtes-vous certain de vouloir supprimer définitivement ce diagramme ? Cette action est irréversible.",
            deleteInterviewQuestion: "Êtes-vous sûr de vouloir supprimer cet entretien ? Cette action est irréversible.",
            enableEditMode: "Activer l'édition",
            grantEditPermission: "Accorder le droit d'édition",
            mapReady: "Diagramme prêt dans 10 minutes",
            newProcess: "Nouveau processus",
            pleaseEnsure: "Merci de maintenir la même orthographe pour les parties prenantes et clients dans toutes vos réponses.",
            requestLimit: "Limite de requêtes atteinte. Passez à la version Premium pour continuer sans interruption.",
            reply1: "Fin de l'entretien, merci pour votre contribution.",
            reply2: "Fin de l'entretien, merci pour votre contribution. Nous contacterons l'équipe et reviendrons vers vous ultérieurement.",
            reply3: "J'ai terminé les entretiens, cliquez sur la carte de génération pour voir le résultat.",
            reply4: "Pouvez-vous dresser la liste des collaborateurs manquants ?",
            reply5: "Souhaitez-vous ajouter quelque chose d'autre ou une information supplémentaire que vous jugez utile de nous communiquer ?",
            recentProcesses: "Processus récents",
            sucessDelete: "L'entretien a été supprimé avec succès.",
            // eslint-disable-next-line no-template-curly-in-string
            s1: "Votre carte de processus sera prête dans 10 minutes!-En tant que responsable de processus, vous avez économisé {{time}} minutes d'entretien !",
            // eslint-disable-next-line no-template-curly-in-string
            s2: "Votre carte de processus sera prête dans 10 minutes!-{{time}} minutes d'entretien récupérées ! Famla donne du pouvoir aux responsables de processus comme vous.",
            // eslint-disable-next-line no-template-curly-in-string
            s3: "Votre carte de processus sera prête dans 10 minutes!-{{time}} minutes gagnées sur les entretiens. Responsables de processus, Famla rationalise votre travail.",
            tip: "bon conseil: Documentez la réalité, pas l'idéal, pour révéler le véritable potentiel d'amélioration .",
            validFille: "Fichier invalide : PDF requis, max 5 Mo.",
            validSize: "PDF,JPG,JPEG,PNG,GIF,WEBP (max.5Mo)",
            yourResponse: "Réponse",
            youCannotDelete: "Vous ne pouvez pas supprimer cet entretien car il est déjà terminé.",
        },
        processByGroupView: {
            approvalName: "Approbateur",
            approvalDate: "Date approbation",
            dateFormat: "La date doit être au format YYYY-MM-DD HH:MM:SS",
            diagramLink: "Lien du diagramme",
            formatDate: "AAAA-MM-JJ HH:mm:ss",
            interviewTime: "Temps d'interview gagné",
            lastModifiedDate: "Dernière modification",
            noProcess: "Aucun processus",
            nexRevisionDate: "Prochaine révision",
            ownerName: "Propriétaire",
            processName: "Nom du processus",
            processMetrics: "KPIs processus",
            revisionTriggers: "Motifs révision",
            requestChange: "Demande de modification"

        },

        legalDocs: {
            contact: {
                eitherFill: "Vous pouvez remplir le formulaire ou utiliser les coordonnées ci-dessous.",
                gettingInTouch: "La prise de contact est facile!",
                sendMeCopy: "Envoyer une copie",
            },

            faq: {
                contactUsPage: ' page Contactez-nous',
                p1: "Pensez à vos processus comme à l'ADN de votre organisation - ils sont le schéma directeur de la façon dont les choses sont faites ! Famla a été créé pour aider les organisations de toutes tailles, industries et sites à comprendre, documenter et rationaliser ces flux de travail essentiels. Alors, qu'est-ce qui fait de Famla l'outil idéal pour vous ? Il s'agit de simplicité, de rapidité et d'un puissant moteur d'amélioration de l'IA qui vous aide à travailler plus intelligemment, et non plus difficilement.",
                p2: "Cette première version se concentre sur les diagrammes transversaux (swimlane). Ces diagrammes sont parfaits pour visualiser le flux de travail entre différentes équipes ou départements. Nous ajouterons bientôt d'autres types de diagrammes !",
                p3: "Oui ! Nous prenons en charge les notations communes telles que BPMN (Business Process Model and Notation) afin de garantir la cohérence et la facilité de compréhension.",
                p4: "Bien sûr. Exportez vos cartes de processus sous forme d'images (PNG, JPEG) ou de fichiers PDF - parfaits pour les rapports, les présentations ou pour obtenir un retour d'information. Vous pouvez également inviter des personnes à visualiser les cartes terminées directement dans l'outil pour une collaboration rapide.",
                p5: "Absolument ! Nous avons conçu cet outil en pensant au travail d'équipe. Invitez facilement les membres de votre équipe à collaborer sur des cartes en temps réel, afin de faire de l'amélioration des processus un effort partagé.",
                p6: "Pas encore, mais c'est pour bientôt ! Nous savons à quel point il est important de tout organiser, c'est pourquoi nous travaillons sur la possibilité de joindre des fichiers, des liens, des notes, etc. directement dans vos cartes.",
                p7: "Préparez-vous à la magie des processus ! Notre moteur d'IA analyse vos cartes en utilisant les principes Lean Six Sigma pour identifier les domaines dans lesquels vous pouvez rationaliser et améliorer vos processus.",
                p8: "Définitivement. Nous prenons la sécurité des données au sérieux. Notre plateforme est hébergée sur Google Cloud, ce qui vous garantit une sécurité optimale grâce au cryptage et à toutes les mesures de protection les plus récentes.",
                p9: "Après votre inscription, un membre de notre équipe vous contactera pour discuter de vos besoins et de l'abonnement qui vous convient le mieux. En attendant, plongez dans l'outil, explorez-le et découvrez la puissance de Famla de première main !",
                p10: "Notre équipe d'assistance est là pour vous ! N'hésitez pas à nous contacter à tout moment via notre ",
                p11: "Nous travaillons constamment à l'amélioration de Famla et nous vous invitons à nous faire part des fonctionnalités que vous aimeriez voir apparaître.",
                t1: "Famla est-elle la solution idéale pour mon entreprise ? ",
                t2: "Quels types de diagrammes de processus puis-je construire avec cet outil ?",
                t3: "Cet outil est-il compatible avec les normes de cartographie des processus que je connais déjà ?",
                t4: "Puis-je facilement partager mes cartes terminées avec d'autres personnes ?",
                t5: "Mon équipe et moi-même pouvons-nous travailler ensemble sur une carte de processus ? ",
                t6: "Puis-je connecter d'autres informations à mes cartes de processus ?",
                t7: "Comment cet outil peut-il m'aider à améliorer mes processus ? ",
                t8: "Les données de ma carte de processus sont-elles sécurisées ?",
                t9: "Proposez-vous un abonnement gratuit ou une période d'essai ?",
                t10: "Comment puis-je obtenir de l'aide si j'en ai besoin ?",
            },

            privacy: {
                p1: "Chez Famla AI, nous sommes profondément attachés à la confidentialité et à la sécurité des données de votre entreprise. Nous comprenons le rôle critique que joue l'intégrité des données dans le déploiement réussi des solutions d'IA, et nous avons mis en œuvre des mesures robustes pour assurer leur protection. Cette politique décrit les principes qui guident nos pratiques en matière de collecte, d'utilisation et de sécurité des données.",
                p2: "Nous pouvons intégrer des services tiers pour améliorer certaines fonctionnalités de notre solution d'IA. Ces tiers n'auront accès qu'aux données nécessaires à l'exécution de leurs fonctions spécifiques en notre nom. Nous exigeons de tous nos partenaires des garanties strictes en matière de confidentialité et de sécurité.",
                p3: "Nous réviserons cette politique au fur et à mesure de l'évolution de nos solutions d'IA et des réglementations en matière de protection de la vie privée. Vous serez informé de tout changement significatif et la politique mise à jour sera publiée sur notre site web.",
                p4: "Si vous avez des questions concernant notre politique de confidentialité et de sécurité, veuillez nous contacter à l'adresse suivante",
                privacyAndSecurityPolicy: "Politique de confidentialité et de sécurité",
                t1: "Notre engagement",
                t2: "Informations collectées",
                t3: "Comment nous utilisons vos données",
                t4: "Mesures de sécurité",
                t5: "Protection des droits",
                t6: "Services aux tiers",
                t7: "Mises à jour de la politique",
                ulLi1: "Données requises pour les opérations : Pour fournir nos services d'IA, nous collecterons les données minimales nécessaires pour former, améliorer et fournir des informations par le biais de nos modèles. Ces données peuvent inclure l'organigramme, le nom du client, les données de performance des activités.",
                ulLi2: "Données d'utilisation : Nous pouvons collecter des données d'utilisation anonymes pour comprendre comment notre solution d'IA est utilisée, ce qui nous permet d'optimiser ses performances et de l'adapter aux besoins de votre entreprise.",
                ulLi3: "Informations essentielles sur le compte Pour gérer votre compte et fournir une assistance à la clientèle, nous pouvons recueillir des informations de contact de base telles que le nom de votre entreprise, votre adresse et les adresses électroniques des utilisateurs autorisés.",
                ulLi4: "Amélioration du service : Nous pouvons utiliser des données d'utilisation agrégées et anonymes pour comprendre et améliorer notre solution d'IA pour tous nos clients.",
                ulLi5: "Assistance à la clientèle : Nous pouvons utiliser les informations relatives à votre compte pour fournir une assistance technique, communiquer avec vos utilisateurs autorisés et répondre à vos demandes.",
                ulLi6: "Marketing et informations sur mesure : Nous pouvons analyser des données anonymes et agrégées dérivées de votre utilisation afin de fournir des informations sectorielles plus pertinentes, des tendances et éventuellement des publicités liées à nos services ou aux offres de nos partenaires.",
                ulLi7: "Cryptage : Nous utilisons des méthodes de cryptage standard pour protéger vos données en transit et au repos.",
                ulLi8: "Stockage sécurisé des données : Notre solution d'IA est hébergée sur une infrastructure en nuage sécurisée et gérée par des professionnels, qui fait l'objet d'audits de sécurité réguliers.",
                ulLi9: "Contrôles d'accès : L'accès à vos données est strictement contrôlé et n'est accordé qu'au personnel autorisé qui est contractuellement lié par des accords de confidentialité.",
                ulLi10: "Réponse aux incidents : Nous avons mis en place un plan détaillé de réponse aux incidents afin de remédier rapidement à d'éventuelles violations de la sécurité. En cas de violation, nous vous en informerons conformément à la réglementation en vigueur",
                ulLi11: "Transparence des données : Nous fournissons des informations claires sur la manière dont vos données sont utilisées dans le cadre de cette politique et dans tout accord contractuel.",
                ulLi12: "Accès aux données : Vous avez le droit d'accéder aux données que nous collectons et traitons au nom de votre entreprise.",
                ulLi13: "Rectification des données : Vous avez le droit de demander la correction de toute donnée personnelle inexacte que nous détenons.",
                ulLi14: "Suppression des données : À la fin de votre contrat de service, vous pouvez demander la suppression ou la restitution de vos données conformément à la réglementation en vigueur.",
            },
            terms: {
                effectiveDate: "Date d'entrée en vigueur",
                famlaMay: "Famla peut utiliser vos données pour fournir et améliorer le service, y compris pour :",
                famlaWillTake: "Famla prendra des mesures raisonnables pour protéger vos données contre l'accès, l'utilisation ou la divulgation non autorisés. Ces mesures comprennent :",
                terms_and_condition: "Conditions d'utilisation de Famla AI",
                p1: "Bienvenue sur le site de Famla AI (« Famla », « nous », « notre » ou « nos »), une solution d'entreprise alimentée par l'IA et conçue pour aider les entreprises à découvrir et à documenter leurs processus. Les présentes conditions générales (« Conditions ») régissent l'accès et l'utilisation de Famla par votre organisation, y compris notre site Web, notre logiciel et tous les services connexes (collectivement, le « Service »). Veuillez lire attentivement ces conditions avant d'utiliser le service.Traduit avec DeepL.com (version gratuite)",
                t2: "Acceptance of Terms",
                p2: "En accédant au service ou en l'utilisant, vous acceptez, au nom de votre organisation (« vous » ou « votre »), d'être lié par les présentes conditions. Si vous n'êtes pas d'accord avec une partie des conditions, vous ne pouvez pas accéder au service. ",
                p3: "Pour accéder au Service et l'utiliser, vous devez créer un compte et fournir à Famla des informations exactes et complètes. Vous êtes responsable du maintien de la confidentialité des informations d'identification de votre compte et de toutes les activités qui se produisent sous votre compte. Vous acceptez de nous informer immédiatement de toute utilisation non autorisée de votre compte ou de toute autre violation de la sécurité.  ",
                p4: "Sous réserve de votre respect des présentes Conditions, Famla vous accorde une licence limitée, non exclusive et non transférable pour accéder au Service et l'utiliser à des fins professionnelles internes.",
                p5: "Le Service et son contenu original, ses caractéristiques et ses fonctionnalités sont et resteront la propriété exclusive de Famla et de ses concédants. Le Service est protégé par les droits d'auteur, les marques déposées et d'autres lois de la République d'Irlande et de pays étrangers. Nos marques et notre habillage commercial ne peuvent pas être utilisés en relation avec un produit ou un service sans le consentement écrit préalable de Famla.  ",
                p6: "Vous restez propriétaire de toutes les données, informations et matériels que vous saisissez, téléchargez ou soumettez au service (« vos données »). Cela inclut, sans s'y limiter, les détails de l'organigramme, les noms des clients et les données relatives aux performances des activités.  ",
                p7: "Nous pouvons intégrer des services tiers pour améliorer certaines fonctionnalités de notre solution d'IA. Ces tiers n'auront accès qu'aux données nécessaires à l'exécution de leurs fonctions spécifiques en notre nom et sont tenus de respecter des mesures strictes de protection de la vie privée et de la sécurité.",
                p8: "Le Service est fourni « tel quel » et « tel que disponible » sans aucune garantie d'aucune sorte, expresse ou implicite. Famla décline toute garantie, y compris, mais sans s'y limiter, les garanties implicites de qualité marchande, d'adéquation à un usage particulier et d'absence de contrefaçon. Famla ne garantit pas que le Service sera ininterrompu ou sans erreur, que les défauts seront corrigés, ou que le Service ou le serveur qui le rend disponible sont exempts de virus ou d'autres composants nuisibles. [citer : 1, 2, 3] ",
                p9: "En aucun cas Famla, ses affiliés, directeurs, officiers, employés, agents, fournisseurs ou concédants de licence ne seront responsables des dommages directs, indirects, accessoires, spéciaux, consécutifs ou punitifs, y compris, sans limitation, la perte de profits, de données, d'utilisation, de goodwill ou d'autres pertes intangibles, résultant de ou liés à votre utilisation ou incapacité à utiliser le Service, qu'ils soient basés sur la garantie, le contrat, le délit (y compris la négligence), ou toute autre théorie juridique, même si Famla a été informée de la possibilité de tels dommages.",
                p10: "Vous acceptez de défendre, d'indemniser et de dégager de toute responsabilité Famla, ses affiliés, directeurs, cadres, employés, agents, fournisseurs et concédants de licence contre toute réclamation, responsabilité, dommages, jugements, récompenses, pertes, coûts, dépenses ou frais (y compris les frais raisonnables d'avocat) découlant de ou liés à votre violation des présentes Conditions ou à votre utilisation du Service, y compris, mais sans s'y limiter, votre utilisation du contenu, des services et des produits du Service autre que celle expressément autorisée dans les présentes Conditions, ou votre utilisation de toute information obtenue à partir du Service. ",
                p11: "Le présent accord reste en vigueur jusqu'à ce qu'il soit résilié conformément aux dispositions du présent accord. L'une ou l'autre des parties peut résilier cet Accord pour n'importe quelle raison avec un préavis écrit de [nombre] jours à l'autre partie. Famla peut résilier ou suspendre votre accès au Service immédiatement, sans préavis ni responsabilité, pour quelque raison que ce soit, y compris, sans limitation, si vous enfreignez les Conditions. ",
                p12: "Les présentes conditions sont régies et interprétées conformément aux lois de la République d'Irlande, sans tenir compte des dispositions relatives aux conflits de lois.",
                p13: "Nous nous réservons le droit, à notre seule discrétion, de modifier ou de remplacer les présentes conditions à tout moment. Si une révision est importante, nous donnerons un préavis d'au moins 5 jours avant l'entrée en vigueur des nouvelles conditions. La définition de ce qui constitue une modification substantielle est laissée à notre seule discrétion. ",
                p14: "Ces Conditions constituent l'intégralité de l'accord entre vous et Famla concernant le Service et remplacent toutes les communications et propositions antérieures ou contemporaines, qu'elles soient orales ou écrites, entre vous et Famla.",
                p15: "Si vous avez des questions concernant ces conditions, veuillez nous contacter à l'adresse suivante",
                t3: "Utilisation du service",
                t4: "Inscription au compte",
                t5: "Obtention d'une licence",
                t6: "Restrictions",
                t7: "Propriété intellectuelle",
                t8: "Données",
                t9: 'Propriété des données',
                t10: "Utilisation des données",
                t11: "Sécurité des données",
                t12: "Transparence et accès aux données",
                t13: "Services aux tiers",
                t14: "Clause de non-responsabilité",
                t15: "Restriction de la responsabilité",
                t16: "Indemnisation",
                t17: "Termination",
                t18: "Droit applicable",
                t19: "Modifications des conditions",
                t20: "Intégralité de l'accord",
                ulLi1: "Accorder une licence, accorder une sous-licence, vendre, revendre, louer, transférer, céder, distribuer, partager le temps, ou exploiter commercialement ou mettre le service à la disposition d'un tiers, autre que les utilisateurs autorisés au sein de votre organisation. ",
                ulLi2: "Modifier, adapter ou pirater le service ou tenter de toute autre manière d'obtenir un accès non autorisé au service ou à ses systèmes ou réseaux connexes. ",
                ulLi3: "Utiliser le service d'une manière qui enfreint toute loi ou réglementation fédérale, nationale, locale ou internationale applicable.",
                ulLi4: "Utiliser le service pour traiter toute information personnelle sensible sans avoir obtenu un consentement explicite et mis en place des garanties appropriées. ",
                ulLi5: "Utiliser le service pour transmettre de la publicité non sollicitée ou non autorisée, du matériel promotionnel, du courrier indésirable, du spam, des chaînes de lettres, des systèmes pyramidaux ou toute autre forme de sollicitation.",
                ulLi6: "Utiliser le Service pour usurper l'identité ou tenter d'usurper l'identité de Famla, d'un employé de Famla, d'un autre utilisateur ou de toute autre personne ou entité.",
                ulLi7: "Interférer avec ou perturber l'intégrité ou la performance du Service ou de ses systèmes ou réseaux connexes.",
                ulLi8: "Tenter de déchiffrer, de décompiler, de faire de l'ingénierie inverse ou de découvrir de quelque manière que ce soit le code source ou les idées ou algorithmes sous-jacents du service.  ",
                ulLi9: "Traiter et analyser vos données pour générer des cartes de processus et de la documentation.",
                ulLi10: "Développer et améliorer les modèles et algorithmes d'IA.",
                ulLi11: "Regrouper et anonymiser vos données à des fins de recherche et de développement.",
                ulLi12: "Fournir une assistance technique, communiquer avec vos utilisateurs autorisés et répondre à vos demandes.",
                ulLi13: "Analyser les données anonymes et agrégées dérivées de votre utilisation afin de fournir des informations sectorielles plus pertinentes, des tendances et éventuellement des publicités liées à nos services ou aux offres de nos partenaires.  ",
                ulLi14: "Cryptage de vos données en transit et au repos.",
                ulLi15: "Stockage sécurisé de vos données sur une infrastructure en nuage gérée par des professionnels et soumise à des audits de sécurité réguliers.",
                ulLi16: "Des contrôles d'accès stricts à vos données, accordés uniquement au personnel autorisé qui est contractuellement lié par des accords de confidentialité.",
                ulLi17: "A detailed incident response plan in place to address potential security breaches swiftly.  ",
                ulLi18: "Famla fournit des informations claires sur la manière dont vos données sont utilisées dans le cadre de cette politique et dans tout accord contractuel.",
                ulLi19: "Vous avez le droit d'accéder aux données que nous collectons et traitons pour le compte de votre entreprise.",
                ulLi20: "Vous avez le droit de demander la correction de toute donnée personnelle inexacte que nous détenons.",
                ulLi21: "À la fin de votre contrat de service, vous pouvez demander la suppression ou la restitution de vos données conformément aux réglementations applicables. ",
                youAgreeNotTo: "Vous vous engagez à ne pas :"

            },
        }
    },

    words: {
        add: "Ajouter",
        administrator: "Administrateur",
        address: "Adresse",
        approve: "Approuver",
        cancel: 'Annuler',
        city: "Ville",
        close: 'Fermer',
        comments: "Commentaire",
        country: "Pays",
        create: "Créer",
        revoke: "Retirer",
        delete: "Supprimer",
        demote: "Rétrograder",
        description: 'Description',
        disApprove: "Rejeter",
        edit: "Modifier",
        email: "E-mail",
        english: "Anglais",
        faq: "FAQ",
        file: "Fichier",
        filter: "filtre",
        french: "Français",
        help: 'Aide',
        grant: "Accorder",
        improve: "Améliorer",
        invite: "Inviter",
        inviteAApprover: "Inviter un approbateur",
        languages: "Langues",
        link: "Lien",
        me: "Moi",
        message: "Décrivez votre demande ici",
        name: "Nom",
        no: "non",
        or: "OU",
        organize: "Déplacer le dossier",
        promote: 'Promouvoir',
        process: 'Processus',
        publish: "Publier",
        refresh: "Rafraîchir",
        rename: "Renommer",
        requestApproval: "Demande d'approbation",
        role: "Rôle",
        status: "Statut",
        save: "Sauvegarder",
        search: "Recherche",
        searching: "Recherche",
        send: "Envoyer",
        share: "Partager",
        subject: "Sujet",
        token: "Token",
        thank: "Merci",
        yes: "oui"
    }
}



export default fr