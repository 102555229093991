// Reac Component
import React from "react";
import { useNavigate } from "react-router-dom";

// App Component
import { Routes } from "../../routes";
import { BreadCrumb, SearchInput } from "../../components";
import { FamlaServices } from "../../services";
import { AddAdmin } from "../../views/modal";

// Features
import UsersList from "./usersList";
import { logout, useAppDispatch, useAppSelector } from "../../stores";

//interface
import { iAlert } from "../../interfaces";
import { iUser } from "../../components/type";

//utils
import useSearch from "../../utils/useSearch";
import { useTranslation } from "react-i18next";

const AdminPages = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);
    const [users, setUsers] = React.useState<iUser[]>([]);
    const companyId = auth.session.company_id;
    const [loading, setLoading] = React.useState(false);
    // const [filter, setFilter] = React.useState<string | undefined>('USER');
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    });

    const [pagination, setPagination] = React.useState({
        total: 0,
        per_page: 10,
        last_page: 0,
        current_page: 1,
    });

    const { searchQuery, setSearchQuery, searchResults, searchLoad } = useSearch(`/users/search/my/user`, companyId);

    const logOut = () => {
        dispatch(logout());

        navigate(`${Routes.AUTH_LOGIN}?status=10`);
    };

    async function loadUsers(currentPage: number, per_page: number) {
        setLoading(true);

        try {
            setAlert({ isActive: false, type: '', message: '' });

            let data = await FamlaServices("api_host", `/users/company/${companyId}?page=${currentPage}&limit=${per_page}`, "GET", "", auth.access_token);

            if (data.status === 200) {
                const paginage = data.body.paginage;
                const adminsWithRole = data.body.data.admin.map((user: iUser) => ({ ...user, role: "admin" }));

                const adminsWithTokens = await Promise.all(
                    adminsWithRole.map(async (admin: iUser) => {
                        try {
                            const tokenData = await FamlaServices("api_host", `/request/total_tokens/user?company_id=${companyId}&user_id=${admin._id}`, "GET", "", auth.access_token);

                            if (tokenData.status === 200) {
                                return { ...admin, total_tokens: tokenData.body.data.total_tokens || 0 };
                            } else {
                                console.error(`Failed to fetch tokens for admin ${admin._id}`);
                                return { ...admin, total_tokens: 0 };
                            }
                        } catch (error) {
                            console.error(`Error fetching tokens for admin ${admin._id}:`, error);
                            return { ...admin, total_tokens: 0 };
                        }
                    })
                );

                setUsers(adminsWithTokens);

                setPagination({
                    ...pagination,
                    current_page: paginage.current_page,
                    total: paginage.total,
                    last_page: paginage.last_page
                });
            } else if (data.status === 401 && data.body.description === "You do not have authorisation to continue") {
                setAlert({ isActive: true, type: 'warning', message: 'You do not have authorisation to continue' });
            } else if (data.status === 401) {
                logOut();
            } else {
                setAlert({ isActive: true, type: 'danger', message: 'An error has occurred, please try again later' });
            }
        } catch (e) {
            if (e instanceof Error) {
                console.error(e.message);
            }
        } finally {
            setLoading(false);
        }
    }


    React.useEffect(() => {
        loadUsers(pagination.current_page, pagination.per_page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.access_token]);

    const handleNextPage = () => {
        if (pagination.current_page < pagination.last_page) {
            setPagination((prev) => ({ ...prev, current_page: prev.current_page + 1 }));
            loadUsers(pagination.current_page + 1, pagination.per_page);
        }
    };

    const handlePrevPage = () => {
        if (pagination.current_page > 1) {
            setPagination((prev) => ({ ...prev, current_page: prev.current_page - 1 }));
            loadUsers(pagination.current_page - 1, pagination.per_page);
        }
    };


    const breadCrumb = [
        {
            name: t("dashboard.dashboard"),
            link: Routes.DASHBOARDS,
            isActive: false
        },
        {
            name: t("dashboard.admin"),
            link: Routes.ADMIN_LIST,
            isActive: false
        },
        {
            name: t("dashboard.list"),
            link: "",
            isActive: true
        }
    ];

    // const filterOption = [
    //     { label: "USER", value: "USER" },

    // ]

    return (
        <>
            <div className="container-fluid p-4">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <BreadCrumb name={t("dashboard.admin")} links={breadCrumb} children={<></>} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card rounded-3 bg-dark border-secondary">
                            <form onClick={(e) => e.preventDefault()} className="p-4 row justify-content-between">
                                <div className="col-12 col-md-6 col-lg-6 col-xl-3 col-sm-12 d-flex align-items-center">
                                    <SearchInput
                                        inputType="text"
                                        inputValue={searchQuery}
                                        placeholderValue={`${t('words.name')}`}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
                                        inputContainerClass="w-100"
                                        inputStyle={{ borderRadius: 20 }}
                                        inputClass="bg-black text-light border-0"
                                        iconClass="fa-solid fa-magnifying-glass  "
                                        iconName=""
                                        formClass='rounded-2 bg-black text-light text-bg-dark'
                                        iconContainer='bg-black text-secondary border-0'
                                    />
                                </div>
                                <div className=" col-12 col-md-6 col-lg-6 col-xl-3 col-sm-12">
                                    <button className=" btn btn-primary btn-xs  mt-3 mt-md-0 w-100" data-bs-target='#addAdmin' data-bs-toggle="modal"> {t('dashboard.addNewAdmin')}</button>
                                </div>

                            </form>
                            {alert.isActive &&
                                <div className={`alert alert-${alert.type} mb-0 col-12 col-md-6 col-lg-6 offset-lg-3 my-2`} role="alert">
                                    {alert.message}
                                </div>
                            }
                            <div className="table-responsive border-0">
                                {searchQuery === "" ?
                                    <UsersList loading={loading} featuresDatas={users} context="user" key={1} listType="ADMIN" setUsers={setUsers} />
                                    :
                                    <UsersList loading={!searchLoad} featuresDatas={searchResults} context="user" key={2} setUsers={setUsers} listType="ADMIN" />
                                }
                            </div>
                            <div className="card-footer border-top-0 bg-dark">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination mb-0 justify-content-end">
                                        <li className={`page-item ${pagination.current_page === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link mx-1 rounded" onClick={handlePrevPage}>
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </button>
                                        </li>
                                        <li className={`page-item cursor-pointer  ${pagination.current_page === 1 ? 'active' : ''}`} onClick={() => { loadUsers(1, pagination.per_page) }}>
                                            <button className="page-link mx-1 rounded " >1</button>
                                        </li>
                                        {pagination.last_page >= 2 &&
                                            <li className={`page-item cursor-pointer  ${pagination.current_page === 2 ? 'active' : ''}`} onClick={() => { loadUsers(2, pagination.per_page) }}>
                                                <button className="page-link mx-1 rounded " >2</button>
                                            </li>}
                                        {pagination.last_page >= 3 &&
                                            <li className={`page-item cursor-pointer  ${pagination.current_page === 3 ? 'active' : ''}`} onClick={() => { loadUsers(3, pagination.per_page) }}>
                                                <button className="page-link mx-1 rounded " >3</button>
                                            </li>
                                        }
                                        <li className={`page-item ${pagination.current_page >= pagination.last_page ? 'disabled' : ''}`}>
                                            <button className="page-link mx-1 rounded bg-transparent" onClick={handleNextPage} disabled={pagination.current_page >= pagination.last_page}>
                                                <i className="fa-solid fa-chevron-right text-secondary"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddAdmin setUsers={setUsers} companyId={companyId} />

        </>
    )
};

export default AdminPages;