import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { FamlaServices } from '../../services';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../stores';
import { Spiner } from '../../components';
import { iUser } from '../../components/type';

interface PromoteUserToAdminModalProps {
    targetUserId: string | undefined;
    companyId: string;
    setUsers: Dispatch<SetStateAction<iUser[]>> | undefined;
    // setUsers: Dispatch<SetStateAction<iUser[]>> | undefined;
    users: iUser[];
}

const PromoteUserToAdminModal: React.FC<PromoteUserToAdminModalProps> = ({ targetUserId, companyId, setUsers, users }) => {
    const auth = useAppSelector(state => state.auth);
    const { t } = useTranslation();
    const closeRef = useRef<HTMLButtonElement>(null);
    const [isSubmit, setIsSubmit] = useState(false);

    const [alert, setAlert] = useState({
        isActive: false,
        type: "",
        message: ""
    });

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        setIsSubmit(true);

        const endpoint = `/company/admin/office?company_id=${companyId}&user_id=${targetUserId}`;
        const data = await FamlaServices("api_host", endpoint, "PUT", {}, auth.access_token);

        if (data.status === 200) {
            closeRef.current && closeRef.current.click();
            setAlert({ isActive: false, type: '', message: "" });
            if (setUsers) {
                setUsers(
                    users.map((user: iUser) =>
                        user._id === targetUserId ? { ...user, role: "admin" } : user
                    )
                );
            }
            // setAlert({ isActive: true, type: 'success', message: t('dashboard.successPromotion') });
        } else if (data.status === 401) {
            setAlert({ isActive: true, type: 'warning', message: t('navigation.youAreNotAuthorizedToPerformThisAction') });
        } else if (data.status === 400) {
            setAlert({ isActive: true, type: 'warning', message: t("dashboard.companyNotFound") });
        }
        else if (data.status === 404 && data.body.data.status_code === 400) {
            setAlert({ isActive: true, type: 'warning', message: t("dashboard.companyNotFound") });
        } else {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.anErrorOcured') });
        }
        setIsSubmit(false);
    };

    return (
        <div className="modal fade" id={`promoteUserToAdmin${targetUserId}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {t('dashboard.promoteToAdmin')}</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            {alert.isActive &&
                                <div className={`text-wrap alert alert-${alert.type}`} role="alert">
                                    {alert.message}
                                </div>
                            }
                            <p className="mb-3 text-wrap">{t('dashboard.confirmAdminPromotion')}</p>
                            <div className="modal-footer px-0 mx-0">
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal" ref={closeRef}>{t('words.close')}</button>
                                <button type="submit" className="btn btn-primary btn-sm">
                                    {isSubmit ? <Spiner className='spinner-grow-sm text-light' /> : <span>{t('words.promote')}</span>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromoteUserToAdminModal;
