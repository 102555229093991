// React Component
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// App Component
import { Routes } from '../routes'
import { Colors, Images } from '../constants'
import { TypingParagraph } from "../utils"

interface ChildProps {
    children: React.ReactNode
}

const AuthLayout = (props: ChildProps) => {
    const { children } = props
    const { t } = useTranslation();

    const messages: string[] = [
        t('views.auth.seamlessly'),
        t('views.auth.establish'),
        t('views.auth.eliminate'),
    ];

    const currentYear = new Date().getFullYear();


    return (
        <div className="h-100">
            <div className="row ms-0 me-0">
                <div className="col-sm-12 col-md-6 text-bg-secondary ps-0 pe-0 m-none" style={{ backgroundImage: `url(${Images.bg_2})`, backgroundSize: 'cover' }}>
                    <div className="h-100" style={{ backgroundColor: Colors.bg_dark_blur_2 }}>
                        <div className="p-5">
                            <Link to={Routes.AUTH} className="mb-5">
                                <img className='img-fluid' src={Images.logov1} alt="App Logo" width={150} />
                            </Link>
                            <div className="align-items-center " style={{ paddingTop: 240 }}>
                                <div>
                                    <TypingParagraph messages={messages} delayBetweenMessages={5500} typingSpeed={50} className='fs-5' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-xl-6 text-bg-black m-none ">
                    <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
                        <Link to={Routes.AUTH} className="web-none pb-5" style={{ marginTop: 150 }}>
                            <img className='img-fluid' src={Images.logov1} alt="App Logo" width={100} />
                        </Link>
                        {children}
                        <div className=" web-none text-start text-center mt-5 pb-3 mb-0 " style={{ fontSize: '.7rem' }}>
                            <div >
                                {/* <Link to={``} className="text-light">{t('navigation.termsOfUse')}</Link> */}
                                <a href={Routes.TERMS} className="text-light">{t('navigation.termsAndConditions')}</a>
                                <span className="px-3 text-white"> | </span>
                                <a href={Routes.PRIVACY} className="text-light">{t('navigation.privacyPolicy')}</a>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative m-none w-100 " style={{ marginTop: "-30px", fontSize: '.8rem' }}>
                        <div className="position-absolute top-100 start-50 translate-middle">
                            <div>
                                {/* <Link to={``} className="text-light">{t('navigation.termsOfUse')}</Link> */}
                                <a href={Routes.TERMS} className="text-light">{t('navigation.termsAndConditions')}</a>
                                <span className="px-1 text-white"> | </span>
                                <a href={Routes.PRIVACY} className="text-light">{t('navigation.privacyPolicy')}</a>
                            </div>

                            <p style={{ fontSize: '.7rem' }} className='text-center text-light mt-2'>Copyright ©  {currentYear} Famla. {t("navigation.allRightsReserved")} </p>

                        </div>
                    </div>

                </div>
                <div className="col-sm-12 col-md-6 text-bg-black web-none">
                    <div className="web-none mt-3">
                        <img className='img-fluid' src={Images.logov1} alt="App Logo" width={100} />
                    </div>
                    <div className=" d-flex flex-column justify-content-center align-items-center">
                        <img className='img-fluid' style={{ marginTop: 150 }} src={Images.l1} alt="App Logo" />
                        <p className='text-white text-center mt-2'>For a better experience, Famla is only available in desktop mode.</p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayout