import { env } from '../app.conf';

export default async function FamlaServices(services, link, method, form, token) {
    let headers = {};
    let request = {};
    let body = JSON.stringify(form);
    let url = env[services] + link;

    headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
    };

    if (form instanceof FormData) {
        headers = { Accept: "application/json" };
        body = form;
    }

    if (token) {
        document.cookie = `access_token=${token}; path=/`;
        headers = { ...headers, Authorization: `Bearer ${token}` };
    }

    request = new Request(url, {
        mode: "cors",
        method: method,
        headers: headers,
    });

    if (method !== "GET" && method !== "HEAD") {
        request = new Request(url, {
            mode: "cors",
            method: method,
            headers: headers,
            body,
        });
    }

    const resp = await fetch(request);

    // Récupération du content-type
    const contentType = resp.headers.get("Content-Type");

    // Si c'est un fichier (blob), récupérez-le
    if (contentType && (contentType.includes("text/csv") || contentType.includes("application/xml") || contentType.includes("application/octet-stream"))) {
        const blob = await resp.blob();
        return { status: resp.status, body: blob, contentType };
    }

    // Sinon, essayez de parser le JSON
    const data = await resp.json();
    return { status: resp.status, body: data, contentType };
}
