//React
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

//component
// import { useAppSelector } from "../../stores"
// import { FamlaServices } from '../../services'


//interface

// import { iAlert } from '../../interfaces'
import { Spiner } from '../../components'


interface ChildProps {
    processId: string
    action: (id: string) => void | (() => void);
    title: string
    sentence: string
    actionButton: string
    classNameSentence?: string
    isActiveAlert?: boolean
    alertType?: string
    alertMsg?: string

}


const GenericModalParent = (props: ChildProps) => {
    const { processId, action, title, sentence, actionButton, classNameSentence, isActiveAlert, alertType, alertMsg } = props
    const closeRef = useRef<HTMLButtonElement>(null);
    const [state, setState] = React.useState({
        isSubmit: false,
    })

    const { t } = useTranslation();


    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        setState({ ...state, isSubmit: true });
        if (typeof action === "function") {
            action(processId);
            if (closeRef.current) {
                closeRef.current.click()
            }
        }


        setState({ ...state, isSubmit: false });
    };

    return (
        <div className="modal" data-bs-backdrop="static" id={`genericParent${processId}`} tabIndex={-1} >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark ">
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-white">{title}</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {isActiveAlert &&
                            <div className={`alert alert-${alertType}`} role="alert">
                                {alertMsg}
                            </div>
                        }
                        <p className={`text-theme ${classNameSentence}`}>{sentence}</p>
                    </div>
                    <div className="modal-footer">
                        <button ref={closeRef} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                        {actionButton && <button type="button" onClick={handleSubmit} className="btn btn-primary">{state.isSubmit ? <Spiner className='spinner-border-sm' /> : <>{actionButton}</>}</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GenericModalParent 