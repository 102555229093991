// React Component
import React from 'react'
import { useLocation } from 'react-router-dom'

// interface
interface ChildProps {
    children: React.ReactNode
}

const AppLayout = (propos: ChildProps) => {
    const { children } = propos
    function useIsBack(): boolean {
        const location = useLocation()
        const [isBack, setIsBack] = React.useState(false)

        React.useEffect(() => {
            setIsBack(sessionStorage[location.pathname + "_key"] === location.key)
            sessionStorage[location.pathname + "_key"] = location.key
        }, [location])

        return isBack
    }

    const isBack = useIsBack()
    if (isBack) {
        window.scrollTo(0, 0)
    }


    return (
        <>
            <div className="w-100 newbg-black">
                {children}
            </div>

        </>
    )
}

export default AppLayout