// React Component
import React from 'react';
import { useLocation } from 'react-router-dom';

// App Component
// import HeaderLayout from './HeaderLayout';
import { DashNavigation } from '../../routes';
import { i18n } from '../../services';

interface ChildProps {
    children: React.ReactNode;
}

const DashLayout = (propos: ChildProps) => {
    const [currentLanguage, setCurrentLanguage] = React.useState(() => {
        try {
            return localStorage.getItem('currentLanguage') || navigator.language;
        } catch (error) {
            return navigator.language;
        }
    });
    const { children } = propos;
    function useIsBack(): boolean {
        const location = useLocation();
        const [isBack, setIsBack] = React.useState(false);

        React.useEffect(() => {
            setIsBack(sessionStorage[location.pathname + "_key"] === location.key);
            sessionStorage[location.pathname + "_key"] = location.key;
        }, [location]);

        return isBack;
    }

    const isBack = useIsBack();
    if (isBack) {
        window.scrollTo(0, 0);
    }

    React.useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language: string) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);
        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };

    return (
        <div className='container-fluid position-relative'>
            <div className="row">
                <DashNavigation className='col-md-2 d-none d-md-block bg-dark sidebar m-none' />
                <div className='col-md-10 ms-sm-auto px-md-4 bg-light vh-100 overflow-auto bg-black' id='dashlayout' >
                    {/* <HeaderLayout /> */}

                    {/* <div className="container-fluid p-4"> */}
                    {children}
                    {/* </div> */}
                </div>
            </div>

        </div>
    )
}

export default DashLayout;