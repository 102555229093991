export interface BreadCrumbLink {
    name: string;
    link: string;
    isActive: boolean;
}
interface BreadCrumbProps {
    name: string
    links: BreadCrumbLink[]
    children: React.ReactNode
}

function BreadCrumb(props: BreadCrumbProps) {
    const { children, name, links } = props;

    return (
        <div className="border-bottom border-secondary mb-4 d-lg-flex align-items-center justify-content-between text-light">
            <div className="mb-2 mb-lg-0">
                <h1 className="mb-0 fw-bold "> {name} </h1>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ">
                        {links.map((link, index) => {
                            if (link.isActive) {
                                return (
                                    <li className="breadcrumb-item active text-white" key={index} aria-current="page">
                                        {link.name}
                                    </li>
                                )
                            }
                            else {
                                return (
                                    <li className="breadcrumb-item" key={index}>
                                        <a className="text-decoration-none" href={link.link}> {link.name} </a>
                                    </li>
                                )
                            }
                        })}
                    </ol>
                </nav>
            </div>
            {children}
        </div>
    )
}

export default BreadCrumb;  