// React Component
import React from 'react'
import { useTranslation } from 'react-i18next'

// App Component
import { CardProcess, NewProcessOrGroup, SearchInput } from '../../components'
import { AppLayout, HeaderLayout, ProcessLayout } from '../../layouts'
import { FamlaServices } from '../../services'
import { useAppSelector } from '../../stores'
import { Spiner } from '../../components'
import { Routes } from '../../routes'
import { useSearch } from '../../utils'

// Features
import { iProcess } from '../../components/type'



const ProcessViewAll = () => {
    const users = useAppSelector((state) => state.auth);
    const { t } = useTranslation()
    const [notifProcess, setNotifProcess] = React.useState<{ [key: string]: boolean }>({});
    const [process, setProcess] = React.useState<iProcess[]>([]);
    const [state, setState] = React.useState({
        isLoadingProcess: true,
        isLoadingProject: true,
        process: [],
        project: []
    })
    const { searchQuery, setSearchQuery, searchResults, searchLoad, setFilter, filter } = useSearch("/process/search/my/process");
    const options = [
        { label: "role", value: "ROLE" },
        { label: `${t('words.name')}/${t('words.description')}`, value: "TITLE" }
    ]
    const loadNotificationByProcess = async (processId: string) => {
        try {
            let data = await FamlaServices("api_host", `/notification/by/id/${processId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                return data.body.data;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };


    React.useEffect(() => {
        const getProcessByUser = async () => {
            let data = await FamlaServices("api_host", `/process/my`, "GET", "", users.access_token);
            if (data.status === 200) {
                let processes = data.body.data;
                const notif: { [key: string]: boolean } = {}
                for (const process of processes.slice(0, 3)) {
                    notif[process._id] = await loadNotificationByProcess(process._id)
                }
                setTimeout(() => {
                    setState((prev) => ({ ...prev, isLoadingProcess: false }))
                    setProcess(data.body.data)
                }, 500);
                setNotifProcess(notif)
            }
        }
        getProcessByUser()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users.access_token])


    const deleProcess = async (process_id: string) => {
        setProcess(prevState =>
            prevState.filter((process: { _id: string }) => process._id !== process_id)
        );
        await FamlaServices("api_host", `/process/${process_id}`, "DELETE", "", users.access_token);
    }


    return (
        <AppLayout>
            <>
                <HeaderLayout
                    loadcompany={false}
                    search={true}
                    searchfilter={true}
                    setSearchQuery={setSearchQuery}
                    setFilter={setFilter}
                    filterValue={filter}
                />
                <div className="container-fluid mt-3 pb-3 px-3">
                    {/* <div className="mt-4 m-auto d-flex align-items-center justify-content-center w-75">
                        <SearchInput
                            inputType="text"
                            inputValue={searchQuery}
                            placeholderValue={`${t('words.search')}...`}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
                            inputContainerClass="w-75"
                            inputStyle={{ borderRadius: 20 }}
                            inputClass="bg-black text-light border-0"
                            iconClass="fa-solid fa-magnifying-glass  "
                            iconName=""
                            formClass='rounded-5 bg-black text-light text-bg-dark'
                            iconContainer='bg-black text-light border-0'
                            setFilter={setFilter}
                            filter={true}
                            fiterOption={options}
                        />
                    </div> */}

                    {(searchQuery !== "") ?
                        <ProcessLayout title={`${t('words.searching')}...${searchQuery}`} arrowBack={true} style={{}} addClassName='containerCardProcess'>
                            <>
                                {searchResults?.map((process: iProcess, index: number) => (
                                    <CardProcess
                                        processName={process.title}
                                        thisProcessHasABadge={notifProcess[process._id] || false}
                                        theBadgeHasAValue={false}
                                        badgeValue={0}
                                        thisProcessHasAOwner={true}
                                        owner={users.user.fullname === process.owner.fullname ? 'Me' : process.owner.fullname}
                                        key={index}
                                        route={Routes.PROCESS_INTERVIEW}
                                        deleteProcess={() => deleProcess(process._id)}
                                        description={process.description}
                                        processId={process._id}
                                        folder={false}
                                        setProject={() => { }}
                                        setProcess={setProcess}
                                        elementType='process'

                                    />

                                ))}
                                {(searchResults.length === 0 && searchLoad) &&
                                    <NewProcessOrGroup newElement={t('views.processView.processNotFound')} create='process' noResult={true} />
                                }
                            </>
                        </ProcessLayout>
                        :
                        <ProcessLayout title={t('views.processView.myProcess')} arrowBack={true} style={{}} addClassName={` containerCardProcess overflow-auto${state.process.length <= 5 ? 'vh-100' : ''}`}>
                            <>
                                {state.isLoadingProcess ?
                                    <Spiner className='ms-5 mt-5 spinner-border-sm' />
                                    :
                                    <>
                                        {Array.isArray(process) &&
                                            (process.map((process: iProcess, index: number) => (
                                                <CardProcess
                                                    index={index}
                                                    processName={process.title}
                                                    thisProcessHasABadge={notifProcess[process._id] || false}
                                                    theBadgeHasAValue={false}
                                                    badgeValue={0}
                                                    thisProcessHasAOwner={true}
                                                    owner={users.user.fullname === process.owner.fullname ? 'Me' : process.owner.fullname}
                                                    key={index}
                                                    route={Routes.PROCESS_INTERVIEW}
                                                    deleteProcess={() => deleProcess(process._id)}
                                                    description={process.description}
                                                    processId={process._id}
                                                    folder={false}
                                                    setProject={() => { }}
                                                    setProcess={setProcess}
                                                    elementType='process'

                                                />

                                            )))}

                                    </>
                                }

                            </>
                        </ProcessLayout>
                    }
                </div>
            </>
        </AppLayout>
    )
}

export default ProcessViewAll