import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { FamlaServices } from '../../services';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../stores';
import { Spiner } from '../../components';
import { iInterview, iUser } from '../../components/type';

interface GrantEditPermissionModalProps {
    stackholder: iUser;
    processId: string;
    setInterviews: Dispatch<SetStateAction<iInterview[]>> | undefined;
    interviews: iInterview[];
}

const GrantEditPermissionModal: React.FC<GrantEditPermissionModalProps> = ({
    stackholder,
    processId,
    setInterviews,
    interviews,
}) => {
    const auth = useAppSelector((state) => state.auth);
    const { t } = useTranslation();
    const closeRef = useRef<HTMLButtonElement>(null);
    const [isSubmit, setIsSubmit] = useState(false);

    const [alert, setAlert] = useState({
        isActive: false,
        type: "",
        message: "",
    });

    const handleEditPermission = async (isEditMode: boolean) => {
        setIsSubmit(true);

        const endpoint = `/role/`;
        const method = isEditMode ? "DELETE" : "POST";
        const requestBody = {
            user_id: stackholder._id,
            process_id: processId,
        };

        const data = await FamlaServices("api_host", endpoint, method, requestBody, auth.access_token);

        if (data.body.status_code === 200 || data.body.status_code === 201) {
            closeRef.current && closeRef.current.click();
            setAlert({ isActive: false, type: '', message: "" });

            if (setInterviews) {
                const updatedInterviews = interviews.map((interview) => {
                    if (interview.collaboration.user._id === stackholder._id) {
                        return {
                            ...interview,
                            collaboration: {
                                ...interview.collaboration,
                                user: {
                                    ...interview.collaboration.user,
                                    editMode: !isEditMode,
                                },
                            },
                        };
                    }
                    return interview;
                });

                setInterviews(updatedInterviews);
            }
        } else if (data.body.status_code === 404) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.roleNotFound'), });
        } else if (data.body.status_code === 400 && isEditMode) {
            setAlert({ isActive: true, type: 'danger', message: t('navigation.roleAlreadyExist'), });
        }

        setIsSubmit(false);
    };

    return (
        <div
            className="modal fade"
            id={`grantEditPermission${stackholder._id}`}
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark text-light">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {stackholder.editMode
                                ? t('views.processInterview.disableEditMode')
                                : t('views.processInterview.enableEditMode')}
                        </h5>
                        <button
                            type="button"
                            className="btn-close bg-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                handleEditPermission(stackholder.editMode);
                            }}
                        >
                            {alert.isActive && (
                                <div
                                    className={`text-wrap alert alert-${alert.type}`}
                                    role="alert"
                                >
                                    {alert.message}
                                </div>
                            )}
                            <p className="mb-3 text-wrap">
                                {stackholder.editMode
                                    ? t('views.processInterview.confirmEditPermissionRevoke')
                                    : t('views.processInterview.confirmEditPermissionGrant')}
                            </p>
                            <div className="modal-footer px-0 mx-0">
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-sm"
                                    data-bs-dismiss="modal"
                                    ref={closeRef}
                                >
                                    {t('words.close')}
                                </button>
                                <button type="submit" className="btn btn-primary btn-sm">
                                    {isSubmit ? (
                                        <Spiner className="spinner-grow-sm text-light" />
                                    ) : (
                                        <span>
                                            {stackholder.editMode
                                                ? t('words.revoke')
                                                : t('words.grant')}
                                        </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GrantEditPermissionModal;
