// Recat Component
import { PURGE } from 'redux-persist';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Interface
import { iSessions } from '../../features/auth';
import { iUser } from '../../components/type';

interface authState {
    access_token: string
    refresh_token: string
    logged_in: boolean
    session: iSessions
    user: iUser
}

const initialState: authState = {
    access_token: "",
    refresh_token: "",
    logged_in: false,
    session: {
        _id: undefined,
        user: {
            _id: undefined,
            profile: "",
            fullname: "",
            email: "",
            phone_number: "",
            default_user: "",
            is_actived: false,
            is_deleted: false,
            last_connection: ""
        },
        company_id: "",
        groupe: {
            _id: undefined,
            name: "",
            roles: []
        },
        in_groupe: "",
        device: "",
        status: false
    },
    user: {
        _id: "",
        profile: "",
        fullname: "",
        email: "",
        phone_number: "",
        verified: false,
        verification_code: "",
        password: "",
        is_deleted: false,
        created_at: "",
        updated_at: "",
        last_connection: '',
        editMode:false
    },
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action: PayloadAction<{ token: string, session: iSessions, user: iUser }>) => {
            state.logged_in = true
            state.access_token = action.payload.token
            state.session = action.payload.session
            state.user = action.payload.user
        },
        logout: (state) => {
            state.logged_in = false
            state.access_token = ''
            state.refresh_token = ''
            state.session = {
                _id: undefined,
                user: {
                    _id: undefined,
                    profile: "",
                    fullname: "",
                    email: "",
                    phone_number: "",
                    default_user: "",
                    is_actived: false,
                    is_deleted: false
                },
                company_id: "",
                groupe: {
                    _id: undefined,
                    name: "",
                    roles: []
                },
                in_groupe: "",
                device: "",
                status: false
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.logged_in = false
            state.access_token = ''
            state.session = {
                _id: undefined,
                user: {
                    _id: undefined,
                    profile: "",
                    fullname: "",
                    email: "",
                    phone_number: "",
                    default_user: "",
                    is_actived: false,
                    is_deleted: false
                },
                company_id: "",
                groupe: {
                    _id: undefined,
                    name: "",
                    roles: []
                },
                in_groupe: "",
                device: "",
                status: false
            }
        })
    }
})

export default authSlice.reducer
export const { login, logout } = authSlice.actions